import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Container } from "@mui/system";
import { Modal, Step, StepLabel, Stepper } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { useAppDispatch, useLanguage } from "../../store/hooks";
import { createMemorialPage, editMemorialPage } from "./async-actions";
import { getMemorialPageById } from "../home/async-actions";
import { MemorialsActions } from "../home/actions";
import { HomeSelectors } from "../home/selectors";

import { limitations } from "./limitations";
import NovaButton from "../../components/shared/NovaButton";
import BigFlower from "../../components/shared/icons/big-flower";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import LoaderOverlay from "../../components/LoaderOverlay";
import GeneralInfo from "./GeneralInfo";
import Biography from "./Biography";
import Photos from "./Photos";
import FamilyTree from "./FamilyTree";
import Tributes from "./Tributes";
import ConfirmationModal from "../../components/ConfirmationModal";
import CreateMPModal from "../../components/home/create-mp-modal";
import SuccessfullyFinishedScreen from "../../components/SuccessfullyFinishedScreen";
import UpgradeModal from "../../components/upgrade-modal";
import TranslatedText from "../../components/translated-text";

const initialGeneralInfo = {
   profilePhotoBase64: undefined,
   fullName: undefined,
   nickName: undefined,
   yourRelationship: undefined,
   city: undefined,
   birthDay: undefined,
   deathDay: null,
   isAlive: true,
   briefTribute: undefined,
   isPublicPage: false,
};
const initialBiography = {
   biography: "",
   files: [],
};

const CreateAMemorial = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const language = useLanguage();
   const { memorialId } = useParams();

   const memorialPageType = useSelector(HomeSelectors.getMemorialPageType);
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);
   const isMonumentum =
      (memorialPageType === "monumentum" || editMemorialPageData?.isMonumentum) ?? false;

   const [generalInfo, setGeneralInfo] = useState<{
      profilePhotoBase64?: string;
      fullName?: string;
      nickName?: string;
      yourRelationship?: string;
      city?: string;
      birthDay?: string;
      deathDay: string | null;
      isAlive: boolean;
      briefTribute: any;
      isPublicPage: boolean;
   }>(initialGeneralInfo);
   const [biography, setBiography] = useState<{
      biography: string;
      files: any[];
   }>(initialBiography);
   const [biographyFilesFormData, setBiographyFilesFormData] = useState<any[]>([]);
   const [photos, setPhotos] = useState([]);
   const [familyTree, setFamilyTree] = useState<any>([]);
   const [tributes, setTributes] = useState([]);
   const [activeStep, setActiveStep] = useState(0);

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [confirmExiting, setConfirmExiting] = useState<boolean>(false);
   const [createdMemorial, setCreatedMemorial] = useState<any>(undefined);
   const [openUpgradeCreatedMemorial, setOpenUpgradeCreatedMemorial] =
      useState<boolean>(false);

   const handleGeneralInfoChange = useCallback((value: any, field: string) => {
      setGeneralInfo((oldValue) => ({
         ...oldValue,
         [field]: value,
      }));
   }, []);
   const handleBiographyChange = useCallback((changes: any, field: any) => {
      if (field === "biography")
         setBiography((oldValue) => ({
            ...oldValue,
            biography: changes,
         }));
      else if (field === "files")
         setBiography((oldValue) => ({
            ...oldValue,
            files: changes,
         }));
   }, []);

   const handlePhotosChange = useCallback((changes: any) => setPhotos(changes), []);
   const handleMemoriesChange = useCallback((changes: any) => setTributes(changes), []);
   const handleTreeChange = useCallback((changes: any) => setFamilyTree(changes), []);
   const onClickFinish = useCallback(() => {
      setIsLoading(true);

      const tree =
         familyTree.length > 0
            ? [
                 familyTree?.[0],
                 ...familyTree?.filter((item: any) =>
                    familyTree?.[0].partnersIds?.includes(item.id),
                 ),
                 ...familyTree?.filter((item: any) =>
                    item.parentsIds?.includes(familyTree?.[0].id),
                 ),
                 ...familyTree?.filter(
                    (item: any) =>
                       item.id !== familyTree?.[0].id &&
                       !item.parentsIds?.includes(familyTree?.[0].id) &&
                       !familyTree?.[0].partnersIds?.includes(item.id),
                 ),
              ]
            : [];

      if (memorialId === undefined) {
         dispatch(
            createMemorialPage(
               {
                  ...generalInfo,
                  ...biography,
                  biographyFilesFormData,
                  photos,
                  familyTree: tree.map((item: any, index: number) => ({
                     ...item,
                     sortIndex: index,
                  })),
                  tributes,
                  language: language.domainLanguage,
                  isMonumentum,
               },
               (data) => {
                  setCreatedMemorial(data);
               },
               () => {
                  setIsLoading(false);
               },
            ),
         );
      } else {
         dispatch(
            editMemorialPage(
               {
                  id: parseInt(memorialId),
                  ...generalInfo,
                  ...biography,
                  profilePhotoBase64:
                     generalInfo.profilePhotoBase64 !==
                     editMemorialPageData.profilePhotoUrl
                        ? generalInfo.profilePhotoBase64
                        : undefined,
                  photos: photos.map((photo: any) => ({
                     ...photo,
                     base64:
                        photo.base64 !==
                        editMemorialPageData.photos.find((p: any) => p.id === photo.id)
                           ?.photoUrl
                           ? photo.base64
                           : undefined,
                  })),
                  familyTree:
                     familyTree.length > 0
                        ? familyTree.map((item: any, index: number) => ({
                             ...item,
                             sortIndex: index,
                             bornYear: item.bornYear
                                ? dayjs(item.bornYear).format("YYYY-MM-DD")
                                : null,
                             ...(item?.deathYear
                                ? {
                                     deathYear: dayjs(item.deathYear).format(
                                        "YYYY-MM-DD",
                                     ),
                                  }
                                : {}),
                             photoUrl: undefined,
                          }))
                        : [],
                  tributes: tributes.map((tribute: any) => ({
                     ...tribute,
                     photoBase64:
                        tribute.photoBase64 !==
                        editMemorialPageData.tributes.find(
                           (t: any) => t.id === tribute.id,
                        )?.photoUrl
                           ? tribute.photoBase64
                           : undefined,
                  })),
                  isMonumentum,
               },
               navigate,
               () => {
                  setIsLoading(false);
               },
            ),
         );
      }
   }, [
      dispatch,
      language.domainLanguage,
      memorialId,
      generalInfo,
      biography,
      biographyFilesFormData,
      photos,
      familyTree,
      tributes,
      navigate,
      editMemorialPageData,
      isMonumentum,
   ]);

   const steps = useMemo(
      () => [
         {
            label: <TranslatedText text="create-memorial-page.general.title" />,
            component: (
               <GeneralInfo
                  memorialPageType={memorialPageType}
                  info={generalInfo}
                  isMonumentum={isMonumentum}
                  onChange={handleGeneralInfoChange}
               />
            ),
            completed:
               generalInfo.profilePhotoBase64 !== undefined &&
               generalInfo.fullName?.trim() !== "" &&
               generalInfo.nickName?.trim() !== "" &&
               generalInfo.city?.trim() !== "" &&
               generalInfo.briefTribute?.length > 0 &&
               !isMonumentum
                  ? generalInfo.isAlive
                     ? generalInfo.birthDay !== undefined
                     : generalInfo.birthDay !== undefined && generalInfo.deathDay !== null
                  : true,
            passedLimits: true,
            changed:
               generalInfo.profilePhotoBase64 !== editMemorialPageData?.profilePhotoUrl ||
               generalInfo.fullName !== editMemorialPageData?.fullName ||
               generalInfo.nickName !== editMemorialPageData?.nickName ||
               generalInfo.city !== editMemorialPageData?.city ||
               generalInfo.briefTribute !== editMemorialPageData?.briefTribute ||
               generalInfo.isAlive !== (editMemorialPageData?.deathDay === null) ||
               generalInfo.birthDay !== editMemorialPageData?.birthDay ||
               generalInfo.deathDay !== editMemorialPageData?.deathDay ||
               generalInfo.isPublicPage !== editMemorialPageData?.isPublic,
         },
         {
            label: isMonumentum ? (
               <TranslatedText text="create-memorial-page.information.title" />
            ) : (
               <TranslatedText text="create-memorial-page.biography.title" />
            ),
            component: (
               <Biography
                  biography={biography}
                  isMonumentum={isMonumentum}
                  onChange={handleBiographyChange}
                  onChangeFormData={setBiographyFilesFormData}
               />
            ),
            completed: biography?.biography?.trim() !== "",
            passedLimits: limitations[
               editMemorialPageData?.subscription
                  ? editMemorialPageData.subscription.subscriptionPlan.type
                  : "free"
            ]?.biographyLength
               ? biography.biography.length <=
                 limitations[
                    editMemorialPageData?.subscription
                       ? editMemorialPageData.subscription.subscriptionPlan.type
                       : "free"
                 ]?.biographyLength
               : true,
            changed:
               biography.biography !== editMemorialPageData?.biography ||
               biography.files !== editMemorialPageData?.files,
         },
         {
            label: <TranslatedText text="create-memorial-page.photos.title" />,
            component: <Photos photos={photos} onChange={handlePhotosChange} />,
            completed: photos.length > 0,
            passedLimits: limitations[
               editMemorialPageData?.subscription
                  ? editMemorialPageData.subscription.subscriptionPlan.type
                  : "free"
            ]?.photosLength
               ? photos.length <=
                 limitations[
                    editMemorialPageData?.subscription
                       ? editMemorialPageData.subscription.subscriptionPlan.type
                       : "free"
                 ]?.photosLength
               : true,
            changed:
               JSON.stringify(photos) !==
               JSON.stringify(
                  editMemorialPageData?.photos?.map((photo: any) => ({
                     ...photo,
                     base64: photo.photoUrl,
                  })),
               ),
         },
         ...(!isMonumentum
            ? [
                 {
                    label: (
                       <TranslatedText text="create-memorial-page.family-tree.title" />
                    ),
                    component: (
                       <FamilyTree data={familyTree} onUpdate={handleTreeChange} />
                    ),
                    completed: editMemorialPageData ? true : activeStep > 3,
                    passedLimits: limitations[
                       editMemorialPageData?.subscription
                          ? editMemorialPageData.subscription.subscriptionPlan.type
                          : "free"
                    ]?.treeNodesLength
                       ? familyTree.length <=
                         limitations[
                            editMemorialPageData?.subscription
                               ? editMemorialPageData.subscription.subscriptionPlan.type
                               : "free"
                         ]?.treeNodesLength
                       : true,
                    changed:
                       JSON.stringify(familyTree) !==
                       JSON.stringify(editMemorialPageData?.familyTree),
                 },
              ]
            : []),
         {
            label: <TranslatedText text="create-memorial-page.memories.title" />,
            component: (
               <Tributes
                  generalInformation={generalInfo}
                  memories={tributes}
                  onChange={handleMemoriesChange}
               />
            ),
            completed: tributes.length > 0,
            passedLimits: true,
            changed:
               JSON.stringify(tributes) !==
               JSON.stringify(
                  editMemorialPageData?.tributes?.map((tribute: any) => ({
                     ...tribute,
                     photoBase64: tribute.photoUrl,
                  })),
               ),
         },
      ],
      [
         memorialPageType,
         generalInfo,
         biography,
         photos,
         familyTree,
         tributes,
         handleGeneralInfoChange,
         handleBiographyChange,
         handlePhotosChange,
         handleTreeChange,
         handleMemoriesChange,
         activeStep,
         editMemorialPageData,
         isMonumentum,
      ],
   );

   const onClickNext = useCallback(() => {
      if (activeStep === steps.length - 1) onClickFinish();
      else setActiveStep((oldValue) => oldValue + 1);
   }, [activeStep, steps, onClickFinish]);
   const onClickBack = useCallback(() => setActiveStep((oldValue) => oldValue - 1), []);
   const onClickStep = useCallback((step: number) => setActiveStep(step), []);

   const saveButtonDisabled = steps.some((step) => !step.completed || !step.passedLimits);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      if (memorialId === undefined) {
         dispatch(MemorialsActions.getEditedMemorial(null));
         return;
      }

      dispatch(getMemorialPageById(parseInt(memorialId), navigate));
   }, [dispatch, memorialId, navigate]);

   useEffect(() => {
      if (!editMemorialPageData) {
         setGeneralInfo(initialGeneralInfo);
         setBiography(initialBiography);
         setPhotos([]);
         setFamilyTree([]);
         setTributes([]);

         return;
      }

      setGeneralInfo({
         profilePhotoBase64: editMemorialPageData.profilePhotoUrl,
         fullName: editMemorialPageData.fullName,
         nickName: editMemorialPageData.nickName,
         yourRelationship: editMemorialPageData.yourRelationship,
         city: editMemorialPageData.city,
         birthDay: editMemorialPageData?.birthDay,
         deathDay: editMemorialPageData?.deathDay,
         isAlive: editMemorialPageData.deathDay === null,
         briefTribute: editMemorialPageData.briefTribute,
         isPublicPage: editMemorialPageData.isPublic,
      });

      setBiography({
         biography: editMemorialPageData.biography,
         files: editMemorialPageData.files,
      });

      setPhotos(
         editMemorialPageData.photos.map((photo: any) => ({
            ...photo,
            base64: photo.photoUrl,
         })),
      );

      setFamilyTree(editMemorialPageData.familyTree);

      setTributes(
         editMemorialPageData.tributes.map((tribute: any) => ({
            ...tribute,
            photoBase64: tribute.photoUrl,
         })),
      );
   }, [editMemorialPageData]);

   useEffect(() => {
      window.onbeforeunload = () => {
         if (steps.some((step) => step.changed)) return false;
      };

      return () => {
         window.onbeforeunload = null;
      };
   }, [steps]);

   if (!memorialPageType && !memorialId) {
      return <CreateMPModal />;
   }

   return (
      <>
         <Container
            sx={{
               padding: "80px 0",
               position: "relative",
            }}
         >
            <LoaderOverlay
               isLoading={
                  isLoading || (memorialId !== undefined && !editMemorialPageData)
               }
               loadingText={
                  memorialId === undefined
                     ? "Creating Memorial"
                     : editMemorialPageData
                     ? "Saving Memorial"
                     : "Loading Memorial"
               }
            >
               {memorialId === undefined || editMemorialPageData?.isMine ? (
                  <Box
                     sx={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #E9DDC8",
                        borderRadius: "16px",
                        overflow: "hidden",
                     }}
                  >
                     <Box
                        sx={{
                           padding: "32px",
                           borderBottom: "1px solid #E9DDC8",
                           backgroundColor: "#FEFDFC",
                        }}
                     >
                        <Stepper
                           activeStep={activeStep}
                           alternativeLabel
                           sx={{
                              ".MuiStepConnector-line": {
                                 borderColor: "#E9DDC8",
                                 borderWidth: "2px",
                              },
                           }}
                        >
                           {steps.map((step, index) => {
                              const withChanges =
                                 memorialId !== undefined &&
                                 editMemorialPageData &&
                                 step.changed;
                              const withError =
                                 (!step.completed || !step.passedLimits) &&
                                 (editMemorialPageData
                                    ? !step.passedLimits
                                    : activeStep > index) &&
                                 index !== 4;

                              return (
                                 <Step
                                    sx={{
                                       cursor: "default",
                                       userSelect: "none",
                                       ".MuiStepConnector-horizontal": {
                                          marginTop: "4px",
                                       },
                                       ".MuiStepConnector-horizontal span": {
                                          borderWidth: "1px",
                                          borderStyle: "solid",
                                          borderColor:
                                             activeStep > index - 1 &&
                                             memorialId === undefined
                                                ? "#CAA45D"
                                                : "#CAD1E280",
                                       },
                                       ".MuiStepIcon-root": {
                                          width: "32px",
                                          height: "32px",
                                          color: "transparent",
                                          border: "2px solid #CAD1E2",
                                          borderRadius: "999px",
                                          cursor: "pointer",
                                       },
                                       ".MuiStepIcon-root .MuiStepIcon-text": {
                                          fill: "#777E90",
                                       },
                                       ".MuiStepIcon-root.Mui-active": {
                                          color: "transparent",
                                          borderColor: "#CAA45D",
                                          borderWidth: "4px",
                                       },
                                       ".MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
                                          fill: "#151623",
                                       },
                                       ".MuiStepIcon-root.Mui-completed": {
                                          color: "#0F0F1C",
                                          border: "none",
                                       },
                                       ".MuiStepIcon-root.Mui-active.Mui-completed": {
                                          color: "#CAA45D",
                                       },
                                       ".MuiStepIcon-root.Mui-error": {
                                          color: "#d32f2f",
                                          border: "none",
                                          borderRadius: "0px",
                                       },
                                       ".MuiStepLabel-label, .MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-error":
                                          {
                                             color: withError
                                                ? "#d32f2f"
                                                : withChanges
                                                ? "#e39c02"
                                                : step.completed || activeStep === index
                                                ? "#151623"
                                                : "#777E90",
                                             marginTop: withError
                                                ? "2px !important"
                                                : "8px !important",
                                          },
                                    }}
                                    completed={memorialId === undefined && step.completed}
                                    onClick={() => onClickStep(index)}
                                    key={index}
                                 >
                                    <StepLabel
                                       error={withError}
                                       icon={
                                          withError ? (
                                             <ErrorIcon
                                                sx={{
                                                   width: "38px",
                                                   height: "38px",
                                                   color: "#d32f2f",
                                                }}
                                             />
                                          ) : undefined
                                       }
                                    >
                                       {step.label}
                                       <NovaText
                                          type={NovaTextTypes.BaseNormal}
                                          style={{
                                             fontSize: "12px",
                                             lineHeight: "18px",
                                             margin: "0px",
                                             opacity: 0.8,
                                          }}
                                       >
                                          {withError ? (
                                             step.passedLimits ? (
                                                <TranslatedText text="messages.blank-fields" />
                                             ) : (
                                                <TranslatedText text="messages.exceeded-limits" />
                                             )
                                          ) : withChanges ? (
                                             <TranslatedText text="messages.unsaved-changes" />
                                          ) : (
                                             ""
                                          )}
                                       </NovaText>
                                    </StepLabel>
                                 </Step>
                              );
                           })}
                        </Stepper>
                     </Box>

                     <Box
                        sx={{
                           position: "relative",
                           padding: "60px",
                        }}
                     >
                        <Box
                           sx={{
                              position: "relative",
                              zIndex: 2,
                           }}
                        >
                           {steps[activeStep].component}

                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: "row",
                                 justifyContent: "flex-end",
                                 paddingTop: "48px",
                                 gap: 1,
                              }}
                           >
                              {memorialId !== undefined &&
                                 activeStep !== steps.length - 1 && (
                                    <Box
                                       sx={{
                                          display: {
                                             xs: "none",
                                             sm: "block",
                                          },
                                          width: "fit-content",
                                          marginRight: "auto",
                                       }}
                                    >
                                       <NovaButton
                                          disabled={saveButtonDisabled}
                                          color="inherit"
                                          style={{
                                             borderRadius: "32px",
                                             background: "#fff",
                                             color: "#000",
                                             border: "1px solid #CAA45D",
                                             ":hover": {
                                                border: "1px solid #1B1B48",
                                             },
                                          }}
                                          onClick={onClickFinish}
                                       >
                                          <TranslatedText text="buttons.save" />
                                       </NovaButton>
                                    </Box>
                                 )}

                              <NovaButton
                                 color="inherit"
                                 disabled={activeStep === 0}
                                 style={{
                                    borderRadius: "32px",
                                    background: "#fff",
                                    color: "#000",
                                    border: "1px solid #CAA45D",
                                    ":hover": {
                                       border: "1px solid #1B1B48",
                                    },
                                 }}
                                 onClick={onClickBack}
                              >
                                 <TranslatedText text="buttons.back" />
                              </NovaButton>

                              <NovaButton
                                 disabled={
                                    saveButtonDisabled && activeStep === steps.length - 1
                                 }
                                 style={{
                                    borderRadius: "32px",
                                    borderWidth: "1px",
                                 }}
                                 onClick={onClickNext}
                              >
                                 {activeStep === steps.length - 1 ? (
                                    memorialId ? (
                                       <TranslatedText text="buttons.save" />
                                    ) : (
                                       <TranslatedText text="buttons.create" />
                                    )
                                 ) : (
                                    <TranslatedText text="buttons.next" />
                                 )}
                              </NovaButton>
                           </Box>
                        </Box>

                        <Box
                           sx={{
                              position: "absolute",
                              top: "40px",
                              right: "-350px",
                              opacity: 0.7,
                              zIndex: 1,
                           }}
                        >
                           <BigFlower size={800} />
                        </Box>
                     </Box>
                  </Box>
               ) : undefined}
            </LoaderOverlay>
         </Container>

         <ConfirmationModal
            isOpened={confirmExiting}
            message="You have unsaved changes. Do you want to exit?"
            onCancelCallback={() => setConfirmExiting(false)}
            onConfirmCallback={() => navigate("../dashboard")}
         />

         <Modal
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               outline: "none",
            }}
            open={createdMemorial !== undefined}
            onClose={() => navigate("/")}
         >
            <Box
               sx={{
                  width: "800px",
                  backgroundColor: "#ffffff",
                  borderRadius: "24px",
                  padding: "40px",
                  overflow: "hidden",
               }}
            >
               <SuccessfullyFinishedScreen
                  title="Memorial Page Created Successfully"
                  text={
                     <>
                        You can edit your memorial page
                        <br />
                        at any time by visiting your dashboard.
                     </>
                  }
                  buttonText="Preview Page"
                  onClickButton={() => navigate(`/memorial-page/${createdMemorial.id}`)}
                  otherButtons={
                     <>
                        <NovaButton
                           style={{
                              borderRadius: "32px",
                           }}
                           onClick={() => setOpenUpgradeCreatedMemorial(true)}
                        >
                           Upgrade Plan
                        </NovaButton>
                     </>
                  }
               />
            </Box>
         </Modal>

         <UpgradeModal
            page={createdMemorial}
            open={openUpgradeCreatedMemorial}
            onClose={() => setOpenUpgradeCreatedMemorial(false)}
         />
      </>
   );
};

export default CreateAMemorial;
